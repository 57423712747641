<template>
  <div>
    <!-- rfq icon  -->
    <span class="svg-icon">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="920.000000pt"
        height="775.000000pt"
        viewBox="0 0 920.000000 775.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,775.000000) scale(0.100000,-0.100000)"
          fill="#fff"
          stroke="none"
        >
          <path
            d="M4510 7615 c-217 -33 -404 -144 -556 -330 -43 -54 -124 -186 -124
-204 0 -6 -9 -28 -20 -48 -11 -21 -20 -45 -20 -53 0 -8 -10 -44 -22 -80 -22
-64 -22 -76 -26 -750 -2 -432 -7 -689 -13 -695 -7 -7 -443 -12 -1247 -15
-1095 -5 -1246 -8 -1317 -22 -44 -9 -97 -24 -117 -32 -21 -9 -46 -16 -56 -16
-11 0 -25 -6 -31 -14 -7 -8 -23 -17 -36 -20 -38 -10 -143 -84 -213 -151 -59
-56 -122 -135 -122 -154 0 -4 -9 -16 -20 -26 -11 -10 -20 -26 -20 -35 0 -9 -4
-20 -10 -26 -16 -16 -51 -125 -64 -198 -6 -38 -17 -71 -24 -74 -16 -5 -17
-339 -1 -344 6 -2 17 -35 24 -73 15 -76 50 -184 65 -199 6 -6 10 -15 10 -22 0
-35 129 -199 206 -264 80 -67 253 -160 298 -160 9 0 29 -6 44 -14 15 -7 63
-16 107 -20 87 -8 96 -12 134 -71 39 -61 44 -70 65 -106 10 -19 40 -65 65
-104 25 -38 52 -81 59 -95 7 -14 20 -37 30 -52 59 -92 112 -181 112 -188 0 -4
7 -14 16 -21 9 -7 20 -22 25 -34 5 -11 21 -38 34 -60 14 -22 28 -47 32 -55 3
-8 14 -24 23 -35 10 -11 26 -36 36 -55 11 -19 28 -49 40 -65 11 -17 24 -37 27
-45 4 -8 25 -42 47 -75 21 -33 56 -89 77 -124 21 -35 45 -74 53 -86 8 -12 33
-53 55 -90 22 -37 47 -77 55 -90 8 -12 32 -51 53 -86 21 -35 58 -95 82 -134
82 -131 110 -177 128 -208 9 -18 35 -59 57 -92 21 -33 54 -85 71 -115 18 -30
53 -86 78 -125 25 -38 51 -79 57 -90 22 -41 40 -70 72 -117 17 -26 32 -52 32
-56 0 -5 9 -19 20 -32 11 -13 20 -28 20 -35 0 -7 9 -20 20 -30 11 -10 20 -22
20 -26 0 -19 64 -100 102 -131 l42 -33 1046 -2 c798 -2 1051 -6 1068 -15 13
-7 54 -51 90 -98 78 -100 174 -198 252 -257 30 -23 62 -49 71 -58 9 -8 19 -15
22 -15 4 0 29 -15 55 -33 26 -18 58 -38 72 -45 14 -6 32 -16 40 -21 8 -6 35
-19 60 -30 25 -10 66 -29 91 -40 25 -12 50 -21 57 -21 6 0 26 -6 44 -14 60
-26 211 -56 343 -67 143 -12 405 2 480 26 22 7 66 18 97 25 32 6 60 16 63 21
4 5 15 9 26 9 36 0 126 50 162 89 25 28 36 50 40 87 13 106 -22 174 -111 216
-57 27 -85 26 -184 -11 -173 -62 -247 -75 -438 -75 -133 0 -187 4 -225 16 -27
9 -67 19 -87 23 -20 3 -50 12 -65 20 -15 8 -33 15 -40 15 -12 0 -81 31 -113
51 -8 5 -25 13 -38 19 -138 60 -386 302 -460 450 -6 14 -23 44 -37 68 -13 24
-27 53 -31 65 -3 12 -12 36 -19 52 -12 28 -22 62 -44 153 -5 20 -15 88 -22
150 -12 113 -2 276 22 375 23 95 32 124 44 152 8 17 25 55 39 86 90 200 256
389 451 514 42 27 178 95 189 95 5 0 28 8 50 18 99 45 212 63 391 62 147 0
183 -3 265 -24 237 -61 387 -143 568 -311 55 -51 168 -190 190 -235 7 -14 26
-50 42 -80 16 -30 30 -63 30 -72 0 -9 4 -19 9 -22 15 -9 58 -155 75 -254 35
-199 6 -475 -68 -637 -35 -77 -69 -143 -91 -180 -45 -76 -75 -144 -75 -172 1
-40 41 -117 77 -147 40 -34 113 -48 175 -34 38 8 56 19 94 61 25 28 54 67 65
86 11 20 31 56 45 81 49 86 145 348 144 393 0 11 8 44 17 75 26 84 26 482 1
564 -10 31 -18 67 -18 80 0 26 -33 132 -61 194 -11 23 -19 46 -19 52 0 10 -28
73 -50 112 -5 8 -23 42 -40 75 -18 33 -35 62 -39 65 -4 3 -24 31 -45 63 -41
62 -51 74 -162 189 -41 42 -74 85 -74 96 0 11 8 30 19 43 10 13 20 29 22 34 4
9 75 127 109 180 8 13 32 52 53 87 20 34 57 95 82 133 24 39 58 93 74 121 45
75 56 82 146 90 44 4 92 13 107 20 15 8 35 14 44 14 17 0 97 34 169 72 67 36
137 88 188 142 52 55 147 190 147 210 0 7 4 16 10 22 15 15 51 123 65 199 7
38 18 71 24 73 16 5 15 339 -1 344 -7 3 -18 36 -24 74 -20 117 -58 205 -146
339 -52 81 -238 235 -303 251 -13 3 -29 12 -36 20 -6 8 -20 14 -31 14 -10 0
-35 7 -56 16 -20 8 -73 23 -117 32 -71 14 -209 16 -1219 19 -806 3 -1144 7
-1153 15 -10 8 -13 126 -13 612 0 608 -9 841 -36 904 -8 19 -14 41 -14 50 0
36 -79 187 -136 263 -145 190 -361 311 -606 338 -124 14 -300 12 -408 -4z
m400 -449 c188 -39 315 -177 340 -369 15 -118 14 -1329 -2 -1345 -16 -16
-1019 -17 -1049 -2 -18 10 -19 28 -19 603 0 620 6 718 47 807 8 19 25 55 37
80 45 96 183 205 291 229 63 14 283 13 355 -3z m3130 -2195 c79 -24 131 -55
190 -111 92 -89 131 -185 138 -342 3 -79 0 -109 -18 -170 -32 -108 -58 -154
-122 -213 -78 -73 -141 -100 -263 -114 -216 -25 -232 -28 -264 -52 -34 -25
-104 -122 -143 -199 -12 -23 -88 -142 -127 -200 -10 -14 -32 -50 -50 -80 -17
-30 -58 -98 -91 -150 -32 -52 -73 -119 -90 -147 -28 -47 -35 -53 -65 -53 -19
0 -36 4 -39 8 -5 8 -102 41 -226 76 -31 9 -96 20 -145 25 -144 15 -147 20 -85
116 22 33 54 85 72 115 18 30 36 60 40 65 20 24 58 87 58 94 0 5 6 14 13 20 8
6 22 38 32 71 18 59 18 63 0 113 -23 66 -48 93 -112 123 -64 30 -78 30 -144 0
-65 -30 -86 -54 -190 -230 -19 -32 -41 -68 -49 -81 -8 -13 -30 -48 -48 -79
-18 -30 -38 -58 -43 -61 -5 -4 -16 -21 -24 -38 -8 -18 -24 -45 -35 -62 -11
-16 -25 -41 -32 -55 -7 -14 -18 -29 -25 -33 -7 -4 -13 -13 -13 -20 -1 -7 -13
-29 -28 -50 -23 -32 -40 -42 -102 -63 -41 -14 -88 -31 -105 -39 -117 -53 -190
-90 -220 -111 -20 -13 -38 -24 -42 -24 -3 0 -23 -14 -45 -32 -23 -18 -58 -43
-79 -57 -60 -41 -192 -173 -258 -258 -33 -43 -67 -85 -74 -93 -8 -8 -17 -22
-20 -30 -4 -8 -18 -33 -31 -55 -36 -59 -116 -221 -116 -235 0 -7 -6 -26 -14
-43 -25 -57 -55 -163 -62 -218 -3 -30 -13 -79 -20 -109 -19 -74 -19 -350 0
-433 30 -132 34 -176 16 -192 -14 -13 -120 -15 -827 -15 -543 0 -820 3 -839
11 -30 10 -44 29 -120 159 -25 41 -67 110 -94 153 -28 43 -50 81 -50 83 0 2
-16 27 -35 54 -19 27 -35 53 -35 58 0 4 -7 17 -15 27 -21 28 -60 87 -68 105
-4 8 -16 29 -27 45 -11 17 -29 46 -40 65 -11 19 -28 47 -38 61 -9 15 -35 57
-57 94 -22 37 -47 77 -55 90 -8 13 -30 47 -48 77 -18 29 -46 73 -62 98 -16 25
-33 52 -37 60 -4 8 -21 35 -38 60 -17 25 -36 56 -43 70 -7 14 -21 39 -32 55
-11 17 -27 44 -36 60 -9 17 -20 32 -24 35 -4 3 -13 17 -21 32 -28 54 -83 143
-96 156 -7 7 -13 18 -13 23 0 5 -15 31 -32 57 -37 54 -43 64 -93 147 -20 33
-63 103 -95 155 -33 52 -75 122 -95 155 -20 33 -54 87 -75 120 -22 33 -44 70
-49 82 -6 12 -26 45 -46 72 -19 28 -35 53 -35 56 0 14 -85 125 -111 144 -32
23 -49 27 -264 52 -124 14 -183 40 -261 112 -66 61 -92 107 -124 215 -37 125
-20 298 43 417 41 80 182 194 241 196 11 0 37 7 56 14 52 20 6723 16 6790 -4z"
          />
          <path
            d="M2603 3965 c-34 -16 -63 -39 -83 -66 -26 -36 -30 -51 -30 -106 0 -64
1 -67 61 -174 8 -13 20 -32 28 -42 8 -9 19 -26 25 -36 34 -59 92 -154 126
-206 21 -33 54 -85 72 -115 18 -30 42 -69 53 -86 11 -18 35 -58 54 -90 19 -33
49 -79 65 -104 39 -58 47 -71 93 -148 21 -35 43 -71 48 -80 6 -9 31 -48 56
-87 25 -38 54 -83 63 -100 9 -16 34 -57 56 -90 21 -33 62 -98 90 -145 164
-271 185 -302 230 -328 60 -34 146 -31 203 7 60 41 102 123 92 182 -7 40 -61
142 -141 264 -54 84 -61 96 -79 133 -8 17 -19 32 -24 32 -5 0 -14 12 -20 28
-13 31 -20 43 -82 137 -25 39 -54 84 -63 100 -9 17 -37 62 -62 100 -24 39 -49
79 -54 90 -6 11 -28 47 -50 80 -22 33 -47 74 -56 90 -9 17 -38 62 -63 100 -25
39 -56 90 -69 115 -13 25 -29 50 -36 55 -15 13 -96 151 -96 164 0 5 -6 14 -13
18 -7 4 -29 34 -48 66 -54 93 -82 136 -119 186 -26 35 -116 82 -154 80 -11 0
-44 -11 -73 -24z"
          />
          <path
            d="M4652 3963 c-27 -9 -55 -31 -80 -60 l-37 -46 0 -901 0 -901 23 -30
c13 -17 39 -42 59 -57 32 -24 46 -28 104 -28 79 0 124 24 168 88 l26 37 3 820
c2 451 1 857 -3 902 -6 79 -8 83 -49 123 -67 67 -131 82 -214 53z"
          />
          <path
            d="M6414 2547 c-105 -56 -114 -88 -114 -397 0 -137 -4 -252 -10 -260 -8
-13 -55 -16 -287 -20 l-278 -5 -37 -29 c-21 -15 -38 -33 -38 -39 0 -6 -8 -19
-17 -29 -13 -14 -17 -37 -17 -87 0 -62 3 -72 32 -109 65 -82 65 -82 364 -82
192 0 267 -3 276 -12 9 -9 12 -80 12 -258 0 -277 6 -313 65 -370 49 -48 119
-64 188 -43 58 17 89 43 118 100 17 34 19 60 19 291 0 155 4 261 10 273 10 18
24 19 278 19 260 1 268 1 304 23 52 32 77 67 87 124 17 87 -7 152 -76 206 -27
21 -37 22 -306 25 l-278 3 -9 25 c-6 14 -10 136 -10 271 0 227 -2 250 -20 288
-25 52 -53 77 -108 98 -56 22 -98 20 -148 -6z"
          />
        </g>
      </svg>
    </span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
/**
      * component style 
    */
.variants {
  .svg-icon svg {
    width: 33px !important;
  }
}
</style>
